/* HOME PAGE STYLES */
/* Background color: FDD848*/
/* Foregroudn color: AAD59E*/

.page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    width: 75%;
    min-height: 100vh;
    margin: 0 auto;
    /* background-color: blue; */
    color: white;
}

.section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 25%;
    /* background-color: red; */
    /* color: #282c34; */
    color: #2c9c1d;
    border-bottom: 10px solid #176813;
    padding: 25px 0px;
    box-sizing: border-box;
}

.subsection{
    flex-direction: row;
    height: 100%;
    /* background-color: chocolate; */
}

.body li {
    /* Create bullet points */
    list-style-type: disc;
    margin-bottom: 5px;
}

.icon-container {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    /* background-color: #282c34; */
}

.image {
    /* background-color: #282c34; */
    width: 15%;
    height: 100%;
    object-fit: contain;
    /* background-color: antiquewhite; */
}

.icon {
    width: 10%;
    height: auto;
    padding: 10px 10px;
    box-sizing: border-box;
}

.qa {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    box-sizing: border-box;
    /* background-color: #282c34; */
    /* color: #282c34; */
    color: #2c9c1d;
    width: 100%;
    padding: 0px 10px;
    border: 1px solid #2c9c1d;
}

.mobile-qa {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    color: #2c9c1d;
    width: 100%;
    padding: 0px 10px;
    border: 1px solid #2c9c1d;
}

.mobile-qa h3 {
    /* border: 2px solid black; */
    margin: 2vh;
    margin-bottom: 0px;
    text-decoration: underline;
}

.mobile-qa p {
    text-align: center;
    margin-top: 1vh;
}

.section li {
    /* Create bullet points */
    width: 90%;
    text-align: left;
    /* background-color: aqua; */
    margin-top: 1vh;
    margin-left: 10%;
    box-sizing: border-box;

    @media (max-width: 525px) {
        margin-left: 0px; 
        width: 100%;
    }
}

h2 {
    color: #176813;
}

strong {
    color: #176813;
}