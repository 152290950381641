.banner {
    display: flex;
    flex-direction: row;
    width:100%;
    /* background-color: aqua; */
    background-color: #29b424;
    color: white;
    /* border: 1px solid #282c34; */
    padding: 10px 10px;
    box-sizing: border-box;
  }

.mobile-banner {
    display: flex;
    flex-direction: column;
    align-items:center;
}
  
.banner-img {
    padding: 10px 10px;
    width: 15%;
    height: auto; /* maintain aspect ratio */
    /* max-height: 300px; */
    /* background-color: #282c34; */
}

.banner-text {
    flex-direction: column;
    margin-left: 5%;
    /* background-color: #282c34; */
}

.mobile-banner-text {
    display: grid;
    flex-direction: column;
    align-items: center;
}

.banner-text.reverse{
    /* margin-right: 5%; */
    margin-right: 30%;
}

.banner.reverse {
    flex-direction: row-reverse;
}

.banner.light {
    background-color: white;
    color: #29b424;
}

.banner h2 {
    color: white;
}

.banner li {
    margin-left: -5%;
}