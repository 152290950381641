.carousel-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    /* background-color: #282c34; */
    justify-content: center;
    /* overflow: hidden; */
    /* align */
    /* border: 1px solid black; */
    box-sizing: border-box;
}

.image-caption-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* background-color: aqua; */
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    /* overflow: hidden; */
    /* align */
}
  
.carousel-img {
    /* display: flex; */
    width: 70%;
    aspect-ratio: 16/9;
    object-fit: contain;
    background-color: white;
}
  
.carousel-button {
    width: 5%;
    /* height: 100%; */
    /* top: 50%; */
    /* background-color: gray; */
    background-color: #176813;
    /* background-color: #2c9c1d80; */
    /* background-color: #2c9c1d80; */
    /* opacity: 0.5; */
    /* border: 1px solid black; */
    cursor: pointer;
}

.caption {
    width: 100%;
    text-align: center;
    background-color: #2c9c1d;
    color: white;
    border: 1px solid #2c9c1d;
}