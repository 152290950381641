/* NAV BAR STYLES */

.nav-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  /* padding: 0px; */
  padding-left: 3vw;
  background-color: #29b424;
  color: white;
  box-sizing: border-box;
}

.nav-bar-container {
  /* display: flex; */
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background-color: aqua; */
  /* padding: 0px 20px; */
  padding-right: 30px
}

.nav-bar li {
  list-style-type: none;
}

.nav-bar a {
  text-decoration: none;
  color: white;
}

ul {
  margin: 0;
}

.mobile-navbar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  /* height: 5vh; */
  padding: 0px;
  background-color: #29b424;
  color: white;
  box-sizing: border-box;
}

.hamburger-menu {
  font-size: 3vh;
  cursor: pointer;
  padding-left: 3vw;
  padding-top: 0;
  border: none;
  background-color: #29b424;
  color: white;
}

.mobile-navbar-list {
  padding-left: 0;
  background-color: lightgray;
}

.mobile-navbar-container li {
  width: 100%;
  background-color: white;
  list-style-type: none;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-size: 2vh;
  padding-left: 3vw;
  margin-bottom: 1px;
}

.link-style {
  color: #29b424; /* Example style */
  text-decoration: none; /* Remove underline */
}