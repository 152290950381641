/* TOP BAR STYLES */

.top-bar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  /* padding: 20px; */
  /* background-color: aqua; */
  color: #176813;
  box-sizing: border-box;
}

.header-container {
  display: flex;
  justify-content: space-between;
  /* background-color: orange; */
  width: 100%;
  /* padding: 0px 20px; */
}

.header-top-bottom-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0px 20px;
}

.header-top-container {
  display: flex;
  justify-content: space-between; /* Adjust as needed */
  align-items: flex-end;
  /* background-color: aquamarine; */
  width: 100%;
  height: 70%;
  margin-bottom: 0px;
}

.header-bottom-container {
  display: flex;
  justify-content: space-between; /* Adjust as needed */ 
  align-items: flex-end;
  /* background-color: red; */
  width: 100%;
  height: 30%;
}

.header-logo-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: blue; */
  width: 50%;
  height: 100%;
}

.title {
  display: flex;
  margin-bottom: 0px;
  /* background-color: antiquewhite; */
  font-size: 30px;
}

.subtitle {
  /* display: flex; */
  font-size: 15px;
  /* background-color: antiquewhite; */
  /* border: 1px solid black; */
}

.logo {
  /* background-color: #282c34; */
  width: 100px;
  height: 100px;
  object-fit: contain;
  /* background-color: antiquewhite; */
}