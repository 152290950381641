/* TOP BAR STYLES */

.top-bar {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: aqua; */
  color: #176813;

  @media (max-width: 525px) {
    height: 15vw; /* Responsive font size */
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  background-color: orange;
  width: 100%;
  /* padding: 0px 20px; */
}

.header-top-bottom-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0px 20px;
}
.header-top-bottom-container-test {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100px;
  @media (max-width: 525px) {
    height: 15vw; /* Responsive font size */
  }
}

.header-top-container {
  display: flex;
  justify-content: space-between; /* Adjust as needed */
  align-items: flex-end;
  /* background-color: aquamarine; */
  width: 100%;
  height: 70%;
  margin-bottom: 0px;
}

.header-bottom-container {
  display: flex;
  justify-content: space-between; /* Adjust as needed */ 
  align-items: flex-end;
  /* background-color: red; */
  width: 100%;
  height: 30%;
}

.header-logo-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: blue;
  /* width: 50%; */
  height: 100%;
}

/* .title {
  display: flex;
  margin-bottom: 0px;
  background-color: antiquewhite;
  font-size: 30px;
  @media (max-width: 525px) {
    font-size: calc(14px + 2vw);
  }
} */

.title {
  padding-left: 3vw;
  font-size: 30px;
  @media (max-width: 525px) {
    font-size: 5vw;
  }
  margin-bottom: 0px;
}

.subtitle {
  /* display: flex; */
  padding-left: 3vw;
  margin-top: 0px;
  font-size: 15px;
  @media (max-width: 525px) {
    font-size: 2.5vw;
  }
}

.logo {
  /* background-color: #282c34; */
  width: 100px;
  height: 100px;
  object-fit: contain;
  @media (max-width: 525px) {
    height: 15vw; /* Responsive font size */
    width: 15vw;
  }
  /* background-color: antiquewhite; */
}